<template>
    <v-layout column>
        <v-layout class="big-toolbar tertiary-background" pt-10 justify-start align-center column>            
            <span class="header"><strong>RASA</strong> - NATURAL LANGUAGE PROCESSOR</span>
            <v-card class="crash-content secondary-background">
                <v-layout column v-if="errorStack.length > 0">
                    <v-layout flex align-center>
                        <v-img
                            eager
                            max-height="100"
                            max-width="100"
                            src="crash_icon.png"
                        ></v-img> 
                        <v-layout column>
                            <span class="title-error text-center">
                                Possível erro detectado! 😵
                            </span>
                            <span class="msg-error text-center">
                                O Rasa ficará fora do ar até que tudo seja resolvido.
                            </span>
                        </v-layout>
                        <v-img
                            eager
                            max-height="100"
                            max-width="100"
                            src="crash_icon.png"
                            style="background-color: transparent;"
                        ></v-img>     
                    </v-layout>
                    <v-card class="mt-6 tertiary-background">
                        <v-card-title
                            class="text-h6"
                            v-text="'Stack de erros:'"
                        ></v-card-title>
                        <v-divider dark></v-divider>
                        <v-divider dark></v-divider>
                        <v-layout 
                            column
                            class="local-custom-container-vlist" 
                        >
                            <v-list-item
                                v-for="(item, i) in errorStack"
                                :key="i"
                            >
                                <v-list-item-content>
                                    <v-layout column>
                                        <v-list-item-title>
                                            {{ item.type }}
                                        </v-list-item-title>
                                        <v-layout flex>
                                            <v-list-item-subtitle 
                                                class="text-wrap pb-1" v-text="item.detail"
                                            ></v-list-item-subtitle>
                                            <v-layout style="min-width: 70px; align-content: end;" class="pa-1" align-end justify-center>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <span class="msg-error" v-on="on">{{
                                                        (item.created_at) | moment("from", "now")
                                                        }}</span>
                                                    </template>
                                                    <span>{{
                                                        (item.created_at) | moment("DD/MM/YYYY HH:mm:ss")
                                                    }}</span>
                                                </v-tooltip>
                                            </v-layout>
                                        </v-layout>
                                        <v-divider dark></v-divider>
                                    </v-layout>
                                </v-list-item-content>
                            </v-list-item>
                        </v-layout>
                    </v-card>
                </v-layout>
                <v-layout column align-center v-else>
                    <span class="title-error text-center">
                        Nenhum erro encontrado! 😏
                    </span>
                    <span class="msg-error text-center">
                        Esse sistema é automatizado, não se preocupe!
                    </span>
                    <v-layout mt-4 justify-center>
                            <v-btn color="teal" text :to="{path: '/home'}">
                                Página principal
                            </v-btn>
                        </v-layout>
                </v-layout>
            </v-card>
        </v-layout>
    </v-layout>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            bla: [
                {type: "Testando erro 1", detail: "90 mensagens travadas no OCR", resolved: false, created_at: '12/12/2023'},
                {type: "Testando erro 2", detail: "Já faz 780 minutos que o serviço quiteja_wpp_agent não recebe mensagem.", resolved: false, created_at: '12/12/2023'},
                // {type: "Testando erro 3", detail: "Foi detectado sessões duplicadas.", resolved: false, created_at: '12/12/2023'},
                // {type: "Testando erro 4", detail: "Foi detectado mais de 2 atendentes em uma mesma conversa.", resolved: false, created_at: '12/12/2023'},
                // {type: "Testando erro 1", detail: "90 mensagens travadas no OCR", resolved: false, created_at: '12/12/2023'},
                // {type: "Testando erro 2", detail: "Já faz 780 minutos que o serviço quiteja_wpp_agent não recebe mensagem.", resolved: false, created_at: '12/12/2023'},
                // {type: "Testando erro 3", detail: "Foi detectado sessões duplicadas.", resolved: false, created_at: '12/12/2023'},
                // {type: "Testando erro 4", detail: "Foi detectado mais de 2 atendentes em uma mesma conversa.", resolved: false, created_at: '12/12/2023'},
                // {type: "Testando erro 1", detail: "90 mensagens travadas no OCR", resolved: false, created_at: '12/12/2023'},
                // {type: "Testando erro 2", detail: "Já faz 780 minutos que o serviço quiteja_wpp_agent não recebe mensagem.", resolved: false, created_at: '12/12/2023'},
                // {type: "Testando erro 3", detail: "Foi detectado sessões duplicadas.", resolved: false, created_at: '12/12/2023'},
                // {type: "Testando erro 4", detail: "Foi detectado mais de 2 atendentes em uma mesma conversa.", resolved: false, created_at: '12/12/2023'},
            ]
        }
    },
    computed: {
        ...mapGetters("rasaHealthCheck", {
            errorStack: "errorStack",
        }),
    }

}
</script>

<style scoped lang="scss">

    .local-custom-container-vlist {
        max-height: 300px;
        overflow-y: auto;

        .v-list-item {
            min-height: 60px;
            padding: px 0px;
        }
        .v-list-item__title {
            align-self: start;
        }
        .v-list-item__content {
            padding-top: 8px;
        }
    }
    .big-toolbar{
        z-index: 3;
        background: linear-gradient(to right, #eef2f3, #8e9eab);

        .header {
            font-size: 24px;
            padding: 10px;
            font-weight: 300;

            strong {
                font-weight: 1000;
            }
        }

    }
    .crash-content {
        display: flex;
        padding: 20px;
        width: 600px;
        min-width: 600px;
        min-height: 200px;
               

        .title-error {
            font-size: 25px !important;
            font-weight: 8000;
            margin: 0 auto;
        }

        .msg-error {
            font-size: 10px;
            opacity: 50%;
        }

    }

    .highligth {
        color: #12c19c;
    }
    .local-custom-created-at {
        font-size: 10px;
    }
    .wrap-text {
        -webkit-line-clamp: unset !important;
    }
</style>